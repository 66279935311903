import React, { useEffect } from 'react';

// Components
import Layout from "../components/layout";
import Seo from "../components/seo";

const EventsPage = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.className = 'ae-emd-script';
    script.src = 'https://cdn.addevent.com/libs/cal/js/cal.embed.t1.init.js';
    script.async = true;
    script.type = 'text/javascript'

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Layout>
      <Seo
        description="Check out our events"
        title="Events"
      />
      <div className="max-w-screen-xl mx-auto px-4 relative z-20 mt-20 pb-20">
        <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">
          Events
        </h2>
        <div className="mt-20">
          <div
            className="ae-emd-cal"
            data-calendar="pz496314"
            data-calendars="pz496314"
            data-calendars-selected="pz496314"
            data-configure="true"
            data-title=""
            data-title-show="true"
            data-today="true"
            data-datenav="true"
            data-date="true"
            data-monthweektoggle="true"
            data-subscribebtn="true"
            data-swimonth="true"
            data-swiweek="true"
            data-swischedule="true"
            data-print="true"
            data-timezone="true"
            data-defaultview="month"
            data-firstday="1"
            data-datetimeformat="2" ></div>
        </div>
      </div>
    </Layout>
  );
};

export default EventsPage;
